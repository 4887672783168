import React, { useRef, useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useMyAppWideContext } from "context/my-app-wide-context";
import "./AboutIntro.css";

const AboutIntro = () => {
  const data = useStaticQuery(graphql`
    query AboutIntroQuery {
      markdownRemark(frontmatter: { type: { eq: "business-information" } }) {
        frontmatter {
          about {
            firmCulture {
              mission
              values
              vision
            }
          }
        }
      }
    }
  `);

  const {
    markdownRemark: {
      frontmatter: {
        about: { firmCulture: firmEthos },
      },
    },
  } = data;

  const firmEthosArray = Object.entries(firmEthos);
  firmEthosArray[2][0] = firmEthosArray[2][0] + "  ";
  firmEthosArray.sort((a, b) => b[0].length - a[0].length);

  const context = useMyAppWideContext();
  let aboutVideoLoading = "";
  let dispatch = "";
  if (context) aboutVideoLoading = context.aboutVideoLoading;
  if (context) dispatch = context.dispatch;
  const loaderWrapperRef = useRef(null);

  useEffect(() => {
    if (!aboutVideoLoading) {
      loaderWrapperRef.current.classList.add("inactive");
    }
  }, [aboutVideoLoading]);

  return (
    <section className="about-intro-section container">
      <div className="row">
        <div className="col-md-6 d-flex flex-column justify-content-between">
          <h1 className="mb-2 title">Providing Legal Solutions</h1>
          <p className="fs-6">
            Established in 2018, at Kingsbench Attorneys, we believe in the
            transformative power of law. We are not simply legal practitioners;
            we are architects of justice, builders of bridges, and voices for
            the voiceless. This deeply held conviction forms the bedrock of our
            vision, mission, and values.
          </p>
          <p className="fs-6">
            Discover the guiding principles that fuel our passion, the
            unwavering commitment that shapes our actions, and the unwavering
            dedication that defines who we are.
          </p>
          <TabbedPanel data={firmEthosArray} />
        </div>
        <div className="col-md-6 d-flex flex-column justify-content-center">
          <div className="video-wrapper position-relative">
            <div ref={loaderWrapperRef} className="loader-wrapper">
              <div id="loader"></div>
            </div>
            <video
              src="/vid/lawyer-vid.mp4"
              loop
              muted
              autoPlay
              onCanPlay={() =>
                dispatch({ type: "SET_ABOUT_VIDEO_LOADING", payload: false })
              }
            ></video>
          </div>
        </div>
        <hr className="my-4" style={{ backgroundColor: "grey" }}></hr>
      </div>
    </section>
  );
};

export default AboutIntro;

const TabbedPanel = ({ data }) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const description = data[currentTabIndex][1];

  return (
    <div className={`about-us-overview-tabs mt-3`}>
      <ul className={`tab-list`}>
        {data.map((item, index) => (
          <li
            key={item[0]}
            className={`col-sm-4 ${index === currentTabIndex ? "active" : ""}`}
          >
            <button
              className={`tab-control`}
              onClick={() => setCurrentTabIndex(index)}
            >
              <h3>{item[0]}</h3>
            </button>
          </li>
        ))}
      </ul>
      <div className={`tab-panel active`}>
        <p className="active">
          {Array.isArray(description) ? (
            <ul>
              {description.map((item) => (
                <li>{item}</li>
              ))}
            </ul>
          ) : (
            description
          )}
        </p>
      </div>
    </div>
  );
};
