import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PracticeAreasOverviewGrid = () => {
  const data = useStaticQuery(graphql`
    query {
      practiceAreasData: markdownRemark(
        frontmatter: { type: { eq: "business-information" } }
      ) {
        frontmatter {
          practiceAreas {
            practiceAreasList {
              overview
              iconName
              heading
            }
          }
        }
      }
    }
  `);

  const {
    practiceAreasData: {
      frontmatter: {
        practiceAreas: { practiceAreasList },
      },
    },
  } = data;

  return (
    <section
      id="practice-areas-overview-grid"
      style={{ backgroundImage: "url(/img/bg.jpg)" }}
    >
      <div className="container py-3">
        <div className="row">
          <h1 className="text-lg-center">Practice Areas</h1>
          {practiceAreasList.map((item) => (
            <div key={item.heading} className="col-md-4">
              <div className="text-center mt-3">
                <div
                  style={{
                    width: "100%",
                    display: "inline-block",
                  }}
                >
                  <div className="feature-item">
                    <FontAwesomeIcon
                      icon={item.iconName}
                      size="4x"
                      width="1.12em"
                      color="goldenrod"
                      background="black"
                      border-radius="50%"
                      padding="0.15em"
                      style={{
                        color: "goldenrod",
                        borderRadius: "50%",
                        backgroundColor: "black",
                      }}
                    />
                    <h3>
                      <strong>{item.heading}</strong>
                    </h3>
                  </div>
                </div>
              </div>
              <p className="px-2">{item.overview}</p>
              <Link
                className="btn m-auto d-block"
                to="/practice-areas/"
                style={{ alignSelf: "end", width: "fit-content" }}
              >
                Learn more
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PracticeAreasOverviewGrid;
