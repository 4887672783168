import React from "react";
import Layout from "components/Layout";
import PracticeAreasOverviewGrid from "components/PracticeAreasOverviewGrid";
import AboutIntro from "components/AboutIntro";
import AttorneyProfile from "components/AttorneyProfile";
import WhyChooseUs from "components/WhyChooseUs";

const About = () => {
  return (
    <Layout>
      <section className="my-4">
        <AboutIntro />
        <AttorneyProfile />
        <WhyChooseUs />
        <PracticeAreasOverviewGrid />
      </section>
    </Layout>
  );
};

export default About;
