import React from "react";
import "./AttorneyProfile.css";

const AttorneyProfile = () => {
  return (
    <section id="attorney-profile" className="pt-5">
      <div className="container">
        <div className="row">
          <h1 className="title text-center">Meet the Senior Attorney</h1>
          <div className="attorney-graphics p-4 p-lg-5 col-md-7 d-flex flex-column justify-content-center">
            <div className="d-flex">
              <div
                className="col-8 pic-wrapper"
                style={{
                  borderLeft: "solid 2px black",
                  borderRight: "solid 2px black",
                }}
              >
                <img
                  className="img-fluid"
                  src="/img/kings-photograph1.jpg"
                  alt="attorney-snapshot1"
                />
              </div>
              <div className="d-flex flex-column col-4 justify-content-center">
                <div className="pic-wrapper">
                  <img
                    className="img-fluid"
                    src="/img/kings-photograph3.jpg"
                    alt="attorney-snapshot3"
                  />
                </div>
                <div className="pic-wrapper">
                  <img
                    className="img-fluid"
                    src="/img/kings-photograph2.jpg"
                    alt="attorney-snapshot2"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="attorney-details col-md-5 d-flex flex-column justify-content-center">
            <p className="attorney-details-paragraph position-relative">
              <img
                className="img-fluid position-absolute"
                alt="vector-filler-graphic"
                src="/img/law-vector1.png"
                style={{ right: "0" }}
              />
              <strong className="fs-6">Etibo Orowo King</strong> is a skilled
              and experienced lawyer who has been practicing law for over 8
              years. He has a proven track record of success in a variety of
              legal matters, and is highly respected by his peers and clients
              alike. Orowo King is a graduate of Delta State University. He is
              passionate about his work and is dedicated to providing his
              clients with the highest quality legal representation. He is a
              strong advocate for his clients and is always willing to go the
              extra mile to achieve the best possible outcome for their case. He
              is a member of the Nigerian Bar Association. He is also active in
              his community and volunteers his time to a number of charitable
              organizations.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AttorneyProfile;
