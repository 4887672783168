import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import "./WhyChooseUs.css";

const WhyChooseUs = () => {
  const data = useStaticQuery(graphql`
    query WhyChooseUsQuery {
      markdownRemark(frontmatter: { type: { eq: "business-information" } }) {
        frontmatter {
          about {
            whyChooseUs {
              details
              heading
            }
          }
        }
      }
    }
  `);

  const {
    markdownRemark: {
      frontmatter: {
        about: { whyChooseUs },
      },
    },
  } = data;

  return (
    <section id="why-choose-us" className="container mt-4">
      <hr style={{ backgroundColor: "grey" }}></hr>
      <div className="row align-items-center">
        <h1 className="text-center">Why Choose Us</h1>
        <div className="col-md-6">
          <ul className="mb-0 ps-0">
            <>
              {whyChooseUs.map((item, index) => (
                <li key={item.heading} className="d-flex">
                  <span className="me-3">0{index + 1}</span>
                  <span>
                    <h2>{item.heading}</h2>
                    <p>{item.details}</p>
                  </span>
                </li>
              ))}
              <br />
            </>
          </ul>
        </div>
        <div className="col-md-6">
          <img className="img-fluid" src="/img/blind-justice.jpg" />
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
